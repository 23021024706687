import React from "react"
import clsx from "clsx"

const ResponseMessage = React.forwardRef(({ message, type }, ref) => {
  return (
    <p
      className={clsx(
        { "error-msg": type === "error" },
        { "success-msg": type === "success" },
        { "info-msg": type === "info" }
      )}
      ref={ref}
    >
      {message}
    </p>
  )
})

export default ResponseMessage
