import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetGGFXImage from '../common/site/get-ggfx-image';
import { ImageModule } from "../common/Image_Module"
import './assets/styles/_index.scss';
import { getWhatsAppURL } from "../../site/utils"
import { registerInterestURL } from '../../site/urls';

const OffPlanMoreProjects = (props) => {

    const OFFPLAN_DETAILS = gql`
        query GetAllOffplans ($price: String!) {
            offplans(
                limit: 3
                where: {search_type: "sales", publish: "true", price_gt: $price}
            ) {
                id
                area
                display_address
                slug
                search_type
                publish
                price
                images
                imagetransforms
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
                crm_negotiator_id
                title
                description
            }
        }
    `;

    const offPlanid = props?.offplanData?.id;
    const offPlanPrice = props?.offplanData?.price;
    
    const { data: dataOffPlan } = useQuery(OFFPLAN_DETAILS, {
        variables: { price: offPlanPrice.toString() }
    });

    if (!dataOffPlan) {
        return null;
    }

    const allOffPlans = dataOffPlan?.offplans?.filter(offplanValue => offPlanid !== offplanValue?.id);

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <>
            {
                allOffPlans.length > 0 &&
                <section className="offplan-moreprojects-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <h3 className="offplan-more-heading">More Off Plan Projects</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider className="offplan-properties-slider" {...settings}>
                                    {
                                        allOffPlans.map((offPlandata, i) =>  {

                                        let processedImages = JSON.stringify({});
                                        if (offPlandata?.imagetransforms?.images_Transforms) {
                                            processedImages = offPlandata.imagetransforms.images_Transforms;
                                        }

                                        let propertyUrl = (offPlandata.search_type === "sales" ? "/off-plan-property-for-sale/" : "/off-plan-property-for-rent/")+offPlandata.slug+"-"+offPlandata.id+"/";
                                        let telephoneUrl = offPlandata?.crm_negotiator_id?.phone.replace(/\D/g, "")
                                        return (
                                            <div className="offplan-properties-slide">
                                                <div className="offplan-properties-img-zoom">
                                                    <Link to={propertyUrl}>
                                                        <ImageModule
                                                            imagename={"property.images.featuredProperties"}
                                                            ImageSrc={offPlandata?.images[0]}
                                                            ggfx_results={offPlandata?.ggfx_results}
                                                            altText={offPlandata?.display_address}
                                                            strapi_id={offPlandata?.id}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="offplan-properties-text-wrapper">
                                                    {
                                                        offPlandata?.description &&
                                                        <p className="display-address display-desc">{offPlandata?.description}</p>
                                                    }
                                                    {
                                                        offPlandata?.display_address &&
                                                        <Link to={propertyUrl}>
                                                            <div className="price-details">{offPlandata?.display_address}</div>
                                                        </Link>
                                                    }
                                                    {
                                                        offPlandata?.area &&
                                                        // <div className="location-details off-plan d-flex align-items-center">
                                                        <div className="location-details off-plan d-flex">
                                                            <i className="icon icon-map-pin"></i>
                                                            <span>{offPlandata?.area}</span>
                                                        </div>
                                                    }
                                                    <ul className="off-plan-list">
                                                        {
                                                            offPlandata?.price &&
                                                            <li className="list-details">From AED {offPlandata?.price.toLocaleString()}</li>
                                                        }
                                                        {
                                                            offPlandata?.title &&
                                                            <li className="list-details">{offPlandata?.title}</li>
                                                        }
                                                        <li className="list-details">Q4 - 2024 Handover</li>
                                                    </ul>
                                                    <ul className="list-inline off-plan-contact-list d-flex align-items-center">
                                                        <li className="list-inline-item">
                                                            <Link to={`${registerInterestURL}?pid=${offPlandata?.id}`} className="d-flex align-items-center">
                                                                <i className="icon icon-team-email"></i>
                                                                <span>Enquire</span>
                                                            </Link>
                                                        </li>
                                                        {
                                                            offPlandata?.crm_negotiator_id?.phone &&
                                                            <li className="list-inline-item hovericon">
                                                                <a href={`tel: ${telephoneUrl}`} ><span>{offPlandata?.crm_negotiator_id?.phone}</span></a>
                                                            </li>
                                                        }
                                                        {
                                                            offPlandata?.crm_negotiator_id?.phone &&
                                                            <li className="list-inline-item">
                                                                <a href={getWhatsAppURL(offPlandata?.crm_negotiator_id?.phone)} target='_blank' className="d-flex align-items-center">
                                                                    <i className="icon icon-team-whatsapp"></i>
                                                                    <span>WhatsApp</span>
                                                                </a>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            )
                                        })  
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default OffPlanMoreProjects