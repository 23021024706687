const BASE_URL = `https://ggfx-whiteandco.s3.eu-west-2.amazonaws.com/`;

const getTransformedImageUrl = (source, imagePath, transformSizes) => {
    if (!imagePath) return "";

    // Extract the image path relative to BASE_URL
    const relativeImagePath = imagePath.replace(BASE_URL, "");

    // Find the matching image in the ggfx_results
    const matchedImage = source?.ggfx_results?.find(item => item.src_cftle === relativeImagePath);

    // Return the first available transformed image URL or the original image path
    return transformSizes
        .map(size => matchedImage?.transforms?.find(item => item.transform === size)?.url)
        .find(Boolean) || imagePath;
};

export const propertyDetImage = (property) => {
    const transformSizes = ["448x320", "376x280", "336x188", "688x520"];
    const defaultImage = property?.images?.[0]?.url || "";

    return getTransformedImageUrl(property, defaultImage, transformSizes);
};

export const useGgfxImage = (Page) => {
    const transformSizes = ["336t300", "208t240", "448x280", "352x386"];
    const imageUrl = Page?.banner_section?.banner_image?.url ||
        Page?.tile_image?.url ||
        Page?.image?.url ||
        "";

    return getTransformedImageUrl(Page, imageUrl, transformSizes);
};
