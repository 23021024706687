import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import './assets/styles/_index.scss';
import DownloadBrochurePopup from '../DownloadBrochurePopup/DownloadBrochurePopup';
import { registerInterestURL } from '../../site/urls';

const OffPlanBlocks = (props) => {

    const {floorplan, brochure} = props

    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <section className="offplan-blocks-wrapper">
            <Container>
                <Row>
                    {(brochure?.length && brochure[0].url) && <Col xl={3} md={6}>
                        <div className="offplan-card">
                            <i className="icon icon-offplan-brochure"></i>
                            <div className="offplan-block-heading">Project Brochure</div>
                            <div className="offplan-block-text">Lorem ipsum dolor sit amet lipsum consectetur adipi scing elit.</div>
                            <button className="button button-primary" onClick={() => setIsModalOpen(true)}>Download Brochure</button>
                        </div>
                    </Col>}
                    {floorplan?.length && floorplan[0].url && <Col xl={3} md={6}>
                        <div className="offplan-card">
                            <i className="icon icon-offplan-floor"></i>
                            <div className="offplan-block-heading">Floorplan</div>
                            <div className="offplan-block-text">Lorem ipsum dolor sit amet lipsum consectetur adipi scing elit.</div>
                            <button onClick={() =>window.open(floorplan[0].url, "_blank")} className="button button-primary">View floorplan</button>
                        </div>
                    </Col>}
                    {/* <Col xl={3} md={6}>
                        <div className="offplan-card">
                            <i className="icon icon-offplan-payment"></i>
                            <div className="offplan-block-heading">Payment Plan</div>
                            <div className="offplan-block-text">Lorem ipsum dolor sit amet lipsum consectetur adipi scing elit.</div>
                            <button className="button button-primary">View Payment plan</button>
                        </div>
                    </Col> */}
                    <Col xl={3} md={6}>
                        <div className="offplan-card">
                            <i className="icon icon-offplan-register"></i>
                            <div className="offplan-block-heading">Register Your Interest</div>
                            <div className="offplan-block-text">For more information or to view the full brochure, fill out the form.</div>
                            <Link to={`${registerInterestURL}?pid=${props?.offplanData?.id}`} className="button button-primary">Register Interest</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            {brochure?.length && <DownloadBrochurePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} fileUrl={brochure[0].url}/>}
        </section>
    )
}

export default OffPlanBlocks