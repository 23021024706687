import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import {isTablet, isMobile} from 'react-device-detect';
import GetGGFXImage from '../common/site/get-ggfx-image';
import { ImageModule } from "../common/Image_Module"
import './assets/styles/_index.scss';

const OffPlanGallery = (props) => {

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.offplanData?.images
    var propertyLightImages =
    propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    return (
        <section className="offplan-gallery-wrapper">
            {
                props?.offplanData?.images.length > 0 &&
                <Container>
                    <Row>
                        <h3 className="offplan-gallery-heading">Gallery</h3>
                    </Row>
                    <Row>
                        {
                            props?.offplanData?.images.slice(1, 4).map((propImg, i) => {

                                let processedImages = JSON.stringify({});
                                if (props?.offplanData?.imagetransforms?.images_Transforms) {
                                    processedImages = props?.offplanData.imagetransforms.images_Transforms;
                                }

                                return (
                                    <Col lg={4} md={6} className={`${i} ${isTablet ? (i === 0 ? "d-none" : "") : (isMobile ? (i === 0 || i === 1  ? "d-none" : "") : "")}`}>
                                        <div className={`offplan-gallery-img-zoom ${i === 2 ? "img-show" : ""}`}>
                                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, i+1)}>
                                                <ImageModule
                                                    imagename={"offplan.images.detail2"}
                                                    ImageSrc={propImg}
                                                    ggfx_results={props?.offplanData?.ggfx_results}
                                                    altText={props?.offplanData?.display_address}
                                                    strapi_id={props?.offplanData?.id}
                                                />
                                            </a>
                                            {
                                                i === 2 &&
                                                <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)} className="img-show-btn">View More</a>
                                            }
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            }

            {/* Banner image popup */}
            {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    nextSrc={
                        propertyLightImages[
                        (photoIndex + 1) % propertyLightImages.length
                        ]
                    }
                    prevSrc={
                        propertyLightImages[
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
            )}
            {/* Banner image popup */}
        </section>
    )
}

export default OffPlanGallery