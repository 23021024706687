import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import $ from 'jquery';
import parse from "html-react-parser";
import ShowMoreText from "react-show-more-text";
import './assets/styles/_index.scss';
import useDeviceMedia from '../../hooks/useDeviceMedia';

const OffPlanDescription = (props) => {

    const [scroll, setScroll] = useState(false)

    const { isMobile, isTablet } = useDeviceMedia();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 500)
        })
    },[])

    // Read more & less
    const executeOnClick = (isExpanded) => {
        var offSet = isMobile ? 130 : isTablet ? 180 : 200
        if (isExpanded) {
            $(".description-content").addClass("shown");
        } else {
            $(".description-content").removeClass("shown");
            $('html, body').animate({
                scrollTop: $('.description-content').offset().top - offSet
            });
        }
    }
    // Read more & less

    return (
        <section className="offplan-description-wrapper">
            <Container>
                <Row>
                    <Col xl={8}>
                        {
                            props?.offplanData?.long_description &&
                            <div className="property-description-details">
                                <h2 className="description-heading">Description</h2>
                                <ShowMoreText
                                    lines={isMobile ? 12 : isTablet ? 7 : 8}
                                    more="Read More"
                                    less="Read Less"
                                    className="description-content"
                                    anchorClass="show-more-less-clickable"
                                    onClick={executeOnClick}
                                    expanded={false}
                                >
                                    <p>{parse(props?.offplanData?.long_description)}</p>
                                </ShowMoreText>
                                <div className="property-description-divider"></div>
                            </div>
                        }

                        <div className="property-offplan-details d-xl-none">
                            <ul className="offplan-right-list">
                                <li>
                                    <div className="d-flex">
                                        <div className="offplan-right-heading">Handover:</div>
                                        <div className="offplan-right-text">Q4-2025</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="offplan-right-heading">Payment Plan:</div>
                                        <div className="offplan-right-text">80/20</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="offplan-right-heading">Booking:</div>
                                        <div className="offplan-right-text">20%</div>
                                    </div>
                                </li>
                                {
                                    props?.offplanData?.area &&
                                    <li>
                                        <div className="d-flex">
                                            <div className="offplan-right-heading">Location:</div>
                                            <div className="offplan-right-text">{props?.offplanData?.area}</div>
                                        </div>
                                    </li>
                                }
                                {
                                    props?.offplanData?.title &&
                                    <li>
                                        <div className="d-flex">
                                            <div className="offplan-right-heading">Bedrooms:</div>
                                            <div className="offplan-right-text">{props?.offplanData?.title}</div>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={3} className="d-xl-block d-none">
                        <div className={`property-right-side-details sticky-top ${scroll ? "scrolled" : ""}`}>
                            <ul className="offplan-right-list">
                                <li>
                                    <div className="d-flex">
                                        <div className="offplan-right-heading">Handover:</div>
                                        <div className="offplan-right-text">Q4-2025</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="offplan-right-heading">Payment Plan:</div>
                                        <div className="offplan-right-text">80/20</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex">
                                        <div className="offplan-right-heading">Booking:</div>
                                        <div className="offplan-right-text">20%</div>
                                    </div>
                                </li>
                                {
                                    props?.offplanData?.area &&
                                    <li>
                                        <div className="d-flex">
                                            <div className="offplan-right-heading">Location:</div>
                                            <div className="offplan-right-text">{props?.offplanData?.area}</div>
                                        </div>
                                    </li>
                                }
                                {
                                    props?.offplanData?.title &&
                                    <li>
                                        <div className="d-flex">
                                            <div className="offplan-right-heading">Bedrooms:</div>
                                            <div className="offplan-right-text">{props?.offplanData?.title}</div>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OffPlanDescription