import { Link } from "gatsby"
import React from "react"
import { privacyURL, termsURL } from "../../site/urls"

const TermsPolicySection = () => {
  return (
    <>
      <p className="terms-section">
        By submitting the form, you agree to our{" "}
        <Link to={termsURL}>Terms & Conditions</Link> and{" "}
        <Link to={privacyURL}>Privacy Policy</Link>.
      </p>
    </>
  )
}

export default TermsPolicySection
