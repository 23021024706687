import React, { useEffect, useState } from "react"
import DownloadPaymentForm from "../forms/DownloadPaymentForm"
import NewsLetterPopupForm from "../forms/NewsLetterPopupForm"
import "./DownloadBrochurePopup.scss"

const DownloadBrochurePopup = ({ isModalOpen, setIsModalOpen, fileUrl }) => {
  // const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)

  if (!isModalOpen) {
    return null
  }

  return (
    <div className="news-model-wrapper">
      <div className="news-model-container">
        <h3 className="heading">Download the Project Brochure</h3>
        <h4 className="title">Please share your details.</h4>
        <div className="form-container">
          <DownloadPaymentForm fileURL={fileUrl}/>
          <div className="close-btn" onClick={closeModal}>
            <i class="icon-close"></i>
          </div>
        </div>
      </div>
      <div className="modal-backdrop" onClick={closeModal}></div>
    </div>
  )
}

export default DownloadBrochurePopup
